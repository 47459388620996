<template>
  <div>
    <el-tree
      :data="treeData"
      :props="defaultProps"
      :default-checked-keys="checkedKeys"
      node-key="deptId"
      default-expand-all
      :expand-on-click-node="false"
      accordion
      show-checkbox
      @node-click="handleNodeClick"
      @check="handleNodeClick"
      ref="tree"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        {{ node.label }}
      </span>
    </el-tree>
    <el-button @click="submitCheckedKeys" type="primary">提交</el-button>
  </div>
</template>

<script>
import { getAllTree,setCheckedDepts } from '@/api/user'

export default {
  data() {
    return {
      treeData: [], // Your parsed JSON data will be stored here
      checkedKeys: [], // Array to store keys of initially checked nodes
      defaultProps: {
        children: 'children',
        label: 'deptName',
        isLeaf: node => !node.children || node.children.length === 0
      }
    };
  },
  created() {
    getAllTree().then(res => {
      if (res.data) {
        this.treeData = res.data;
        console.log(this.treeData);
        this.setCheckedKeysBasedOnStatus();
        console.log(this.checkedKeys);
      }
    }).catch(error => {
      console.error(error);
    });
  },
  methods: {
    
    setCheckedKeysBasedOnStatus() {
      // 递归函数，用于遍历树的每个节点
      const traverse = (node) => {
        if (node.children && node.children.length > 0) {
          node.children.forEach(child => traverse(child)); // 递归遍历子节点
        } else {
          if (node.status === "1") {
            this.checkedKeys.push(node.deptId); // 如果叶子节点的status为'1'，将其key添加到checkedKeys数组中
          }
        }
      };
      this.treeData.forEach(node => traverse(node)); // 从根节点开始遍历
    },
    handleNodeClick(nodeData) {
      // 当节点被点击时，修改其状态
      nodeData.status = nodeData.status === '1' ? '0' : '1';
      // 如果点击的节点是叶子节点，更新checkedKeys数组
      if (this.defaultProps.isLeaf(nodeData)) {
        if (nodeData.status === '1') {
          this.checkedKeys.push(nodeData.deptId);
        } else {
          const index = this.checkedKeys.indexOf(nodeData.deptId);
          if (index !== -1) {
            this.checkedKeys.splice(index, 1);
          }
        }
      }
      this.setCheckedKeys();
    },
    setCheckedKeys() {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.checkedKeys); // 设置选中的节点
      });
      
    },
    submitCheckedKeys() {
      // 这里添加提交选中节点的逻辑，可以使用this.checkedKeys来获取选中节点的信息
      setCheckedDepts(this.checkedKeys).then(res => {
                        this.$message.success('信息提交成功');
                    }).catch(error => {
                        console.error(error);
                        this.$message.error('信息提交失败');
                    });
      console.log("提交选中节点:", this.checkedKeys);
      // 在这里调用API提交选中节点给服务器
    }
  }
};
</script>

<style scoped>
.custom-tree-node {
  display: block;
  width: 100%;
  line-height: 24px;
}
</style>
